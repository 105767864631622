import React, { useEffect, useState } from "react";
import styles from "./Form.module.scss";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  setPhoneNumber,
  openOptionsPopup,
  closeOptionsPopup,
  setCountry,
} from "store/actions";

// Data
import countries from "data/countries-with-codes-and-flags.json";

// Components
import Popup from "shared/Popup";
import SelectOptions from "components/SelectOptions";

// Functions
import { makeSelectOptions } from "helper/functions";

const Form = () => {
  const { selectedCountry, phoneNumber, isSelectOptionOpen } = useSelector(
    (state) => state.appReducer
  );
  const dispatch = useDispatch();

  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");

  const options = countries.map((country) => {
    return makeSelectOptions(country);
  });

  const selectCountryCode = async (country) => {
    const { label, ...rest } = country;
    dispatch(setCountry(rest));

    localStorage.setItem("country", JSON.stringify(rest));
  };

  const startChat = (e) => {
    e.preventDefault();

    if (!selectedCountry || !mobileNumber) return;
    dispatch(setPhoneNumber(mobileNumber));
    dispatch(openOptionsPopup());
  };

  useEffect(() => {
    if (!phoneNumber) {
      setMobileNumber("");
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (selectedCountry) {
      const getSelectedCountry = countries.find(
        (country) => country.name === selectedCountry.name
      );
      setSelectedCountryOption(makeSelectOptions(getSelectedCountry));
    }
  }, [selectedCountry]);

  return (
    <>
      <div className={styles.Form}>
        <form onSubmit={(e) => startChat(e)}>
          <div className={styles.input}>
            <Select
              placeholder="Select Country"
              value={selectedCountryOption}
              options={options}
              isSearchable
              onChange={(option) => selectCountryCode(option)}
            />
          </div>
          <div className={styles.input}>
            <input
              type="text"
              placeholder="Phone Number"
              className={styles.inputText}
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>
          <div className={styles.button}>
            <button type="submit">Start Chat</button>
          </div>
        </form>
      </div>
      <Popup
        title="Select Option"
        isOpened={isSelectOptionOpen}
        onClose={() => dispatch(closeOptionsPopup())}
      >
        <SelectOptions />
      </Popup>
    </>
  );
};

export default Form;

import React, { useState } from "react";
import styles from "./LeftSide.module.scss";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";

// Assets
import AppStore from "assets/media/lottie/app-store.json";
import PlayStore from "assets/media/lottie/play-store.json";
import GoogleChrome from "assets/media/lottie/google-chrome.json";

// Components
import TopBar from "shared/TopBar";

const LeftSide = () => {
  const { isLeftSideOpen } = useSelector((state) => state.appReducer);
  const [isLooping, setIsLooping] = useState({
    appStore: false,
    playStore: false,
    googleChrome: false,
  });

  return (
    <div
      className={`${styles.LeftSide} ${isLeftSideOpen ? styles.opened : ""}`}
    >
      {/* Top Bar */}
      <div className={styles.topBar}>
        <TopBar />
      </div>

      {/* Main Content */}
      <div className={styles.mainContent}>
        <p>
          Open a chat with any unknown number quickly without the need to save
          it in contacts!
        </p>
      </div>

      {/* Download Buttons */}
      <div className={styles.downloadButtons}>
        <a
          href="https://apps.apple.com/eg/app/messagator/id6444772480"
          target="_blank"
          rel="noreferrer"
          className={styles.downloadButton}
          onPointerEnter={() =>
            setIsLooping({
              appStore: true,
              playStore: false,
              googleChrome: false,
            })
          }
          onPointerLeave={() =>
            setIsLooping({
              appStore: false,
              playStore: false,
              googleChrome: false,
            })
          }
        >
          <Lottie
            animationData={AppStore}
            loop={isLooping.appStore}
            className={styles.lottie}
          />
          App Store
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.engtechnos.messagatorapp"
          target="_blank"
          rel="noreferrer"
          className={`${styles.downloadButton} ${styles.playStore}`}
          onPointerEnter={() =>
            setIsLooping({
              appStore: false,
              playStore: true,
              googleChrome: false,
            })
          }
          onPointerLeave={() =>
            setIsLooping({
              appStore: false,
              playStore: false,
              googleChrome: false,
            })
          }
        >
          <Lottie
            animationData={PlayStore}
            loop={isLooping.playStore}
            className={styles.lottie}
          />
          Play Store
        </a>
        <a
          href="https://chrome.google.com/webstore/detail/messagator/ppbelckiciagdbhjdcoofdfneogmooad"
          target="_blank"
          rel="noreferrer"
          className={`${styles.downloadButton} ${styles.googleChrome}`}
          onPointerEnter={() =>
            setIsLooping({
              appStore: false,
              playStore: false,
              googleChrome: true,
            })
          }
          onPointerLeave={() =>
            setIsLooping({
              appStore: false,
              playStore: false,
              googleChrome: false,
            })
          }
        >
          <Lottie
            animationData={GoogleChrome}
            loop={isLooping.googleChrome}
            className={styles.lottie}
          />
          Chrome <br /> Extension
        </a>
        <div href="/" className={`${styles.downloadButton} ${styles.website}`}>
          Website...
        </div>
      </div>
    </div>
  );
};

export default LeftSide;

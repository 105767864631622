// History Types
export const SET_HISTORY = "SET_HISTORY";
export const ADD_HISTORY_ITEM = "ADD_HISTORY_ITEM";
export const REMOVE_HISTORY_ITEM = "REMOVE_HISTORY_ITEM";
export const REMOVE_ALL_HISTORY_ITEMS = "REMOVE_ALL_HISTORY_ITEMS";
export const SET_OPENED_FROM_HISTORY = "SET_OPENED_FROM_HISTORY";

// Form Types
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";

// Popup Types
export const OPRN_COUNTRIES_POPUP = "OPRN_COUNTRIES_POPUP";
export const CLOSE_COUNTRIES_POPUP = "CLOSE_COUNTRIES_POPUP";
export const OPEN_OPTIONS_POPUP = "OPEN_OPTIONS_POPUP";
export const CLOSE_OPTIONS_POPUP = "CLOSE_OPTIONS_POPUP";

// Left Side Types
export const OPEN_LEFT_SIDE = "OPEN_LEFT_SIDE";
export const CLOSE_LEFT_SIDE = "CLOSE_LEFT_SIDE";

import React from "react";
import styles from "./Home.module.scss";

// Components
import LeftSide from "components/Home/LeftSide";
import RightSide from "components/Home/RightSide";
import TopBar from "shared/TopBar";

const Home = () => {
  return (
    <div className={styles.Home}>
      {/* Top Bar */}
      <div className={styles.topBar}>
        <TopBar />
      </div>
      
      <LeftSide />
      <RightSide />
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import styles from "./TopBar.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import { openLeftSide, closeLeftSide } from "store/actions";

// Components
import AboutPopup from "shared/AboutPopup";

// Assets
import Logo from "assets/media/svg/Logo.svg";
import Icon from "assets/media/svg/Icon.svg";

// Icons
import {
  linkedinIcon,
  twitterIcon,
  facebookIcon,
  barsIcon,
} from "helper/icons";

const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAboutPopup, setShowAboutPopup] = useState(false);
  const [showLeftSide, setShowLeftSide] = useState(false);

  useEffect(() => {
    if (showLeftSide) dispatch(openLeftSide());
    else dispatch(closeLeftSide());
  }, [showLeftSide, dispatch]);

  return (
    <div className={styles.TopBar}>
      <img
        src={Logo}
        alt="Messagator"
        className={styles.logo}
        onClick={() => navigate("/")}
      />

      <img
        src={Icon}
        alt="Messagator"
        className={styles.icon}
        onClick={() => navigate("/")}
      />

      <div className={styles.navlinks}>
        <div className={styles.links}>
          <div className={styles.aboutWrapper}>
            <a
              href="/"
              className={styles.navlink}
              onClick={(e) => {
                e.preventDefault();
                setShowAboutPopup(!showAboutPopup);
              }}
            >
              About
            </a>

            {showAboutPopup && <AboutPopup />}
          </div>

          <Link className={styles.navlink} to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>

        <div className={styles.socialLinks}>
          <a
            className={styles.navlink}
            href="https://www.linkedin.com/company/messagatorapp/"
            target="_blank"
            rel="noreferrer"
          >
            {linkedinIcon(22)}
          </a>

          <a
            className={styles.navlink}
            href="https://www.facebook.com/messagatorapp"
            target="_blank"
            rel="noreferrer"
          >
            {facebookIcon(24, 24)}
          </a>

          <a
            className={styles.navlink}
            href="https://twitter.com/messagatorapp"
            target="_blank"
            rel="noreferrer"
          >
            {twitterIcon(22, 26)}
          </a>

          <a
            className={`${styles.navlink} ${styles.bars}`}
            href="/"
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              setShowLeftSide(!showLeftSide);
            }}
          >
            {barsIcon(29, 25)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;

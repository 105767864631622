import React, { useEffect, useState } from "react";
import styles from "./Avatars.module.scss";

// Helpers
import AvatarsList from "data/AvatarsList";

const Avatars = () => {
  const [avatar, setAvatar] = useState(0);

  const getRandomAvatar = () => {
    const randomAvatar = Math.floor(Math.random() * AvatarsList.length);
    setAvatar(randomAvatar);
  };

  useEffect(() => {
    getRandomAvatar();
  }, []);

  return (
    <div className={styles.Avatar}>
      <img
        className={styles.charachter}
        src={AvatarsList[avatar]}
        alt="Avatar"
      />
    </div>
  );
};

export default Avatars;

import React from "react";
import styles from "./AboutPopup.module.scss";

// Assets
import Me from "assets/media/jpg/Me.jpg";
import BuyMeACoffee from "assets/media/png/buyCoffee.png";

// Icons
import { linkedinIcon, twitterIcon, githubIcon } from "helper/icons";

const AboutPopup = () => {
  return (
    <div className={styles.aboutPopup}>
      <a href={Me} target="_blank" rel="noreferrer">
        <img src={Me} alt="Ammar Yaser" className={styles.me} />
      </a>

      <p>
        Hi, I'm Ammar, a software Engineer from Egypt. I built this app to help
        people send messages to anyone on WhatsApp without saving their number.
        I hope you enjoy using it.
      </p>

      <div className={styles.social}>
        <a
          href="https://github.com/ammarbasuony"
          target="_blank"
          rel="noreferrer"
          className={styles.github}
        >
          {githubIcon(27)}
        </a>
        <a
          href="https://www.linkedin.com/in/ammaryaser/"
          target="_blank"
          rel="noreferrer"
          className={styles.linkedin}
        >
          {linkedinIcon(27)}
        </a>
        <a
          href="https://twitter.com/EngAmmarYaser"
          target="_blank"
          rel="noreferrer"
          className={styles.twitter}
        >
          {twitterIcon(27)}
        </a>
      </div>
      <a
        href="https://www.buymeacoffee.com/ammaryaserh"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={BuyMeACoffee}
          alt="Buy Me A Coffee"
          className={styles.BuyMeACoffee}
        />
      </a>
    </div>
  );
};

export default AboutPopup;

import React, { useState, useEffect } from "react";
import styles from "./RightSide.module.scss";

// Components
import Avatars from "components/Avatars";
import Form from "components/Form";
import History from "components/History";

// Illustration
import {
  greenIllustration,
  grayIllustration,
  thirdIllustration,
  logo,
} from "helper/illustrations";

const RightSide = () => {
  const [isEntered, setIsEntered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsEntered(true);
    }, 1000);
  }, []);

  return (
    <div className={styles.RightSide}>
      <div className={styles.illustrations}>
        <div
          className={`${styles.greenIllustration} ${isEntered && styles.enter}`}
        >
          {greenIllustration()}
        </div>
        <div
          className={`${styles.grayIllustration} ${isEntered && styles.enter}`}
        >
          {grayIllustration()}
        </div>
        <div
          className={`${styles.thirdIllustration} ${isEntered && styles.enter}`}
        >
          {thirdIllustration()}
        </div>
      </div>

      <div className={styles.logo}>{logo()}</div>

      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            <h1>Hi, There!</h1>
            <p>Start texting anyone on WhatsApp</p>
          </div>
          <div className={styles.avatars}>
            <Avatars />
          </div>
        </div>

        <div className={styles.form}>
          <Form />
        </div>

        <div className={styles.history}>
          <History />
        </div>
      </div>
    </div>
  );
};

export default RightSide;

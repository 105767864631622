import Avatar1 from "assets/media/svg/Avatars/1.svg";
import Avatar2 from "assets/media/svg/Avatars/2.svg";
import Avatar3 from "assets/media/svg/Avatars/3.svg";
import Avatar4 from "assets/media/svg/Avatars/4.svg";
import Avatar5 from "assets/media/svg/Avatars/5.svg";
import Avatar6 from "assets/media/svg/Avatars/6.svg";
import Avatar7 from "assets/media/svg/Avatars/7.svg";

const AvatarsList = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
];

export default AvatarsList;

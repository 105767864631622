import React from "react";
import styles from "./Popup.module.scss";

// Icons
import { closeIcon } from "helper/icons";

const Popup = ({ title = "Title", isOpened = false, onClose, children }) => {
  return (
    <div className={`${styles.Popup} ${isOpened ? styles.opened : ""}`}>
      <div className={styles.overlay} onClick={() => onClose()} />
      <div className={styles.content}>
        <div className={styles.head}>
          <div className={styles.close} onClick={() => onClose()}>
            {closeIcon()}
          </div>
          <div className={styles.title}>{title}</div>
        </div>

        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default Popup;

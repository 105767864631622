import React, { useState } from "react";
import styles from "./History.module.scss";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { removeAllHistoryItems } from "store/actions";

// Components
import HistoryItem from "./HistoryItem";
import Popup from "shared/Popup";

// Assets
import Trash from "assets/media/svg/Trash.svg";
import HistoryIcon from "assets/media/svg/historyIcon.svg";

const History = () => {
  const { history } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const deleteAll = async () => {
    setShowPopup(false);
    dispatch(removeAllHistoryItems());

    localStorage.removeItem("history");
  };

  return (
    <div className="container">
      <div className={styles.History}>
        <div className={styles.header}>
          <div className={styles.title}>History</div>
          <div className={styles.deleteAll} onClick={() => setShowPopup(true)}>
            Delete All
          </div>
        </div>

        <div className={styles.historyItems}>
          {history.length ? (
            history.map((item) => <HistoryItem key={item.id} item={item} />)
          ) : (
            <div className={styles.noHistory}>
              <img src={HistoryIcon} alt="No History" />
              <div className={styles.noHistoryText}>No history yet!</div>
            </div>
          )}
        </div>
      </div>

      <Popup
        title="Delete All"
        isOpened={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <div className={styles.deleteAll}>
          <div className={styles.deleteAllIcon}>
            <img src={Trash} alt="Delete All" />
          </div>
          <div className={styles.deleteAllText}>
            Are you sure you want to delete all history?
          </div>
          <div className={styles.deleteAllButtons}>
            <div className={styles.deleteAllButton} onClick={() => deleteAll()}>
              Delete
            </div>
            <div
              className={styles.cancelButton}
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default History;

import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

// Pages
import Home from "pages/Home";
import PrivacyPolicy from "pages/Privacy-Policy";

// Actions
import { setCountry, setHistory } from "store/actions";

const App = () => {
  const dispatch = useDispatch();

  const getDataFromStorage = async () => {
    const country = localStorage.getItem("country");
    const history = localStorage.getItem("history");

    if (country) dispatch(setCountry(JSON.parse(country || "")));
    if (history) dispatch(setHistory(JSON.parse(history || "[]")));
  };

  useEffect(() => {
    getDataFromStorage();
  }, []);

  return (
    <div className="Messagator">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

// Assets
import noFlag from "assets/media/png/No_flag.png";

export const shortenString = (string, length) => {
  if (string?.length > length) return string.substring(0, length) + "...";

  return string;
};

// Function to add three dots in the middle of a string if it's longer than 15 characters
export const longString = (string) => {
  if (string.length > 15) {
    const firstPart = string.substring(0, 7);
    const secondPart = string.substring(string.length - 7, string.length);
    return firstPart + "..." + secondPart;
  }
  return string;
};

export const isNumber = (e) => {
  if (!/\d/.test(e.key) && e.key !== ".") return e.preventDefault();
};

export const dateToReadableFormat = (date) => {
  const d = new Date(date);
  const day = d.toLocaleString("default", { weekday: "short" });
  const month = d.toLocaleString("default", { month: "short" });
  const dayNum = d.getDate();
  const year = d.getFullYear();
  const time = d.toLocaleTimeString();

  return `${day} | ${month} ${dayNum}, ${year} ${time}`;
};

export const makeSelectOptions = (value) => {
  return {
    callCode: value?.callingCodes[0],
    value: `${value?.name} | +${value?.callingCodes[0]}`,
    name: value?.name,
    label: (
      <div className="country">
        <img
          src={value?.flags.png}
          alt={value?.name}
          onError={(e) => {
            e.target.src = noFlag;
          }}
        />
        {shortenString(value?.name, 6)} | +{value?.callingCodes[0]}
      </div>
    ),
  };
};

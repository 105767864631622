/* global chrome */
import React from "react";
import styles from "./SelectOptions.module.scss";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  addHistoryItem,
  setPhoneNumber,
  closeOptionsPopup,
  setOpenedFromHistory,
} from "store/actions";

// Assets
import WhatsAppDesktop from "assets/media/svg/whatsapp-desktop.svg";
import WhatsAppWeb from "assets/media/svg/whatsapp-web.svg";

// Constants
import { CHAT_OPTIONS } from "helper/constants";

// Functions
import { dateToReadableFormat } from "helper/functions";

const SelectOptions = () => {
  const dispatch = useDispatch();
  const { selectedCountry, phoneNumber, history, openedFromHistory } =
    useSelector((state) => state.appReducer);

  const saveInHistory = async (type, fullPhoneNumber) => {
    const isNumberInHistory = history.find(
      (item) =>
        item.fullPhoneNumber ===
        `${selectedCountry.callingCodes[0]}${phoneNumber}`
    );
    if (isNumberInHistory) return;

    if (openedFromHistory) return dispatch(setOpenedFromHistory(false));

    const randomId = Math.floor(Math.random() * 1000000);
    const date = new Date();
    const readableDate = dateToReadableFormat(date);

    const newHistoryItem = {
      id: randomId,
      fullPhoneNumber,
      phoneNumber,
      date,
      readableDate,
      type,
      country: selectedCountry,
    };

    dispatch(addHistoryItem(newHistoryItem));
    dispatch(setOpenedFromHistory(false));
    dispatch(setPhoneNumber(""));

    localStorage.setItem(
      "history",
      JSON.stringify([...history, newHistoryItem])
    );
  };

  const openChat = (type) => {
    // remove the first zero from the phone number if exists
    const phoneNumberWithoutZero = phoneNumber.replace(/^0+/, "");

    const fullPhoneNumber = `${selectedCountry.callCode}${phoneNumberWithoutZero}`;

    saveInHistory(type, fullPhoneNumber);

    if (type === CHAT_OPTIONS.APP)
      window.open(`whatsapp://send?phone=${fullPhoneNumber}`);
    else
      window.open(
        `https://web.whatsapp.com/send?phone=${fullPhoneNumber}`,
        "_blank"
      );

    dispatch(closeOptionsPopup());
  };

  return (
    <div className={styles.SelectOptions}>
      <div className={styles.option} onClick={() => openChat(CHAT_OPTIONS.APP)}>
        <div className={styles.optionIcon}>
          <img src={WhatsAppDesktop} alt="WhatsApp Desktop" />
        </div>
        <div className={styles.optionText}>
          <h3>WhatsApp App</h3>
          <p>Start chat from your desktop/phone</p>
        </div>
      </div>
      <div className={styles.option} onClick={() => openChat(CHAT_OPTIONS.WEB)}>
        <div className={styles.optionIcon}>
          <img src={WhatsAppWeb} alt="WhatsApp Web" />
        </div>
        <div className={styles.optionText}>
          <h3>WhatsApp Web</h3>
          <p>Start chat through your browser</p>
        </div>
      </div>
    </div>
  );
};

export default SelectOptions;

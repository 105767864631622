import {
  // History Types
  SET_HISTORY,
  ADD_HISTORY_ITEM,
  REMOVE_ALL_HISTORY_ITEMS,
  REMOVE_HISTORY_ITEM,
  SET_OPENED_FROM_HISTORY,

  // Form Types
  SET_COUNTRY,
  SET_PHONE_NUMBER,

  // Popup Types
  OPRN_COUNTRIES_POPUP,
  CLOSE_COUNTRIES_POPUP,
  OPEN_OPTIONS_POPUP,
  CLOSE_OPTIONS_POPUP,

  // Left Side Types
  OPEN_LEFT_SIDE,
  CLOSE_LEFT_SIDE,
} from "../types";

const initialState = {
  selectedCountry: null,
  phoneNumber: "",
  history: [],
  openedFromHistory: false,
  isSelectCountryOpen: false,
  isSelectOptionOpen: false,
  isLeftSideOpen: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case ADD_HISTORY_ITEM:
      return {
        ...state,
        history: [...state.history, action.payload],
      };
    case REMOVE_HISTORY_ITEM:
      return {
        ...state,
        history: state.history.filter((item) => item.id !== action.payload),
      };
    case REMOVE_ALL_HISTORY_ITEMS:
      return {
        ...state,
        history: [],
      };
    case SET_OPENED_FROM_HISTORY:
      return {
        ...state,
        openedFromHistory: action.payload,
      };
    case SET_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case OPRN_COUNTRIES_POPUP:
      return {
        ...state,
        isSelectCountryOpen: true,
      };
    case CLOSE_COUNTRIES_POPUP:
      return {
        ...state,
        isSelectCountryOpen: false,
      };
    case OPEN_OPTIONS_POPUP:
      return {
        ...state,
        isSelectOptionOpen: true,
      };
    case CLOSE_OPTIONS_POPUP:
      return {
        ...state,
        isSelectOptionOpen: false,
      };
    case OPEN_LEFT_SIDE:
      return {
        ...state,
        isLeftSideOpen: true,
      };
    case CLOSE_LEFT_SIDE:
      return {
        ...state,
        isLeftSideOpen: false,
      };
    default:
      return state;
  }
};

export default appReducer;

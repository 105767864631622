import {
  // History Types
  SET_HISTORY,
  ADD_HISTORY_ITEM,
  REMOVE_ALL_HISTORY_ITEMS,
  REMOVE_HISTORY_ITEM,
  SET_OPENED_FROM_HISTORY,

  // Form Types
  SET_COUNTRY,
  SET_PHONE_NUMBER,

  // Popup Types
  OPRN_COUNTRIES_POPUP,
  CLOSE_COUNTRIES_POPUP,
  OPEN_OPTIONS_POPUP,
  CLOSE_OPTIONS_POPUP,

  // Left Side Types
  OPEN_LEFT_SIDE,
  CLOSE_LEFT_SIDE,
} from "./types";

// History Actions
export const setHistory = (history) => ({
  type: SET_HISTORY,
  payload: history,
});

export const addHistoryItem = (item) => ({
  type: ADD_HISTORY_ITEM,
  payload: item,
});

export const removeHistoryItem = (id) => ({
  type: REMOVE_HISTORY_ITEM,
  payload: id,
});

export const removeAllHistoryItems = () => ({
  type: REMOVE_ALL_HISTORY_ITEMS,
});

export const setOpenedFromHistory = (value) => ({
  type: SET_OPENED_FROM_HISTORY,
  payload: value,
});

// Form Actions
export const setCountry = (country) => ({
  type: SET_COUNTRY,
  payload: country,
});

export const setPhoneNumber = (number) => ({
  type: SET_PHONE_NUMBER,
  payload: number,
});

// Popup Actions
export const openCountriesPopup = () => ({
  type: OPRN_COUNTRIES_POPUP,
});

export const closeCountriesPopup = () => ({
  type: CLOSE_COUNTRIES_POPUP,
});

export const openOptionsPopup = () => ({
  type: OPEN_OPTIONS_POPUP,
});

export const closeOptionsPopup = () => ({
  type: CLOSE_OPTIONS_POPUP,
});

// Left Side Actions
export const openLeftSide = () => ({
  type: OPEN_LEFT_SIDE,
});

export const closeLeftSide = () => ({
  type: CLOSE_LEFT_SIDE,
});

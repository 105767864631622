import React, { useState, useEffect } from "react";
import styles from "./History.module.scss";
import { useDispatch } from "react-redux";

// Actions
import {
  removeHistoryItem,
  openOptionsPopup,
  setCountry,
  setPhoneNumber,
  setOpenedFromHistory,
} from "store/actions";

// Assets
import WhatsappIcon from "assets/media/svg/whatsappIcon.svg";

// Functions
import { shortenString, longString } from "helper/functions";

const HistoryItem = ({ item }) => {
  const shiftedSpace = 70;

  const dispatch = useDispatch();
  const [initX, setInitX] = useState(0);
  const [shift, setShift] = useState(0);
  const [isDown, setIsDown] = useState(false);

  const removeSwipeEvent = (e) => {
    setIsDown(false);
    setShift(0);
  };

  const onMouseDown = (e) => {
    e.stopPropagation();
    setInitX(e.clientX);
    setIsDown(true);
  };

  const onMouseUp = (e) => {
    e.stopPropagation();
    setIsDown(false);
    setInitX(0);

    if (shift < -35) {
      setShift(-shiftedSpace);
    } else {
      setShift(0);
    }
  };

  const onMouseMove = (e) => {
    e.stopPropagation();

    if (isDown) {
      const currentX = e.clientX;
      const newShift = currentX - initX;

      if (newShift >= -shiftedSpace && newShift <= 0) {
        setShift(newShift);
      }

      if (shift && newShift > 0 && newShift <= shiftedSpace) {
        setShift(-shiftedSpace + newShift);
      }
    }
  };

  const deleteHistoryItem = async (e) => {
    e.stopPropagation();
    dispatch(removeHistoryItem(item.id));

    const newHistory = JSON.stringify(
      JSON.parse(localStorage.getItem("history")).filter(
        (i) => i.id !== item.id
      )
    );

    localStorage.setItem("history", newHistory);
  };

  const openChat = () => {
    dispatch(setCountry(item.country));
    dispatch(setPhoneNumber(item.phoneNumber));
    dispatch(setOpenedFromHistory(true));
    dispatch(openOptionsPopup());
  };

  useEffect(() => {
    window.addEventListener("pointerup", removeSwipeEvent);
  }, []);

  return (
    <div className={styles.HistoryItemWrapper}>
      <div
        className={styles.HistoryItem}
        onPointerDown={onMouseDown}
        onPointerUp={onMouseUp}
        onPointerMove={onMouseMove}
        style={{
          transform: `translateX(${shift}px)`,
          userSelect: `${isDown ? "none" : "auto"}`,
          transition: `${isDown ? "none" : "transform 0.3s ease-in-out"}`,
        }}
      >
        <div className={styles.cardContent}>
          <div className={styles.icon}>
            <img src={WhatsappIcon} alt="WA" />
            <span>{item.type}</span>
          </div>
          <div className={styles.phoneNumber}>
            <span>+{longString(item.fullPhoneNumber)}</span>
            <span className={styles.date}>
              {shortenString(item.readableDate, 29)}
            </span>
          </div>
        </div>

        <div className={styles.openChat} onClick={() => openChat()}>
          Chat
        </div>
      </div>

      <button
        className={styles.deleteButton}
        onClick={deleteHistoryItem}
        onMouseUp={(e) => e.stopPropagation()}
      >
        Delete
      </button>
    </div>
  );
};

export default HistoryItem;

import React from "react";
import styles from "./PrivacyPolicy.module.scss";

// Components
import TopBar from "shared/TopBar";

const PrivacyPolicy = () => {
  return (
    <div className={styles.PrivacyPolicy}>
      <TopBar />
      <div className="entry-content">
        <h1>Privacy Policy</h1>

        <p>
          Messagator takes your privacy seriously. To better protect your
          privacy we provide this privacy policy notice explaining the way your
          personal information is collected and used.
        </p>
        <h2>Collection of Routine Information</h2>
        <p>
          This app track basic information about their users. This information
          includes, but is not limited to, IP addresses, app details, timestamps
          and referring pages. None of this information can personally identify
          specific user to this app. The information is tracked for routine
          administration and maintenance purposes.
        </p>
        <h2>Cookies</h2>
        <p>
          Where necessary, this app uses cookies to store information about a
          visitor’s preferences and history in order to better serve the user
          and/or present the user with customized content.
        </p>
        <h2>Advertisement and Other Third Parties</h2>
        <p>
          Advertising partners and other third parties may use cookies, scripts
          and/or web beacons to track user activities on this app in order to
          display advertisements and other useful information. Such tracking is
          done directly by the third parties through their own servers and is
          subject to their own privacy policies. This app has no access or
          control over these cookies, scripts and/or web beacons that may be
          used by third parties. Learn how to opt out of Google’s cookie usage.
        </p>
        <h2>Links to Third Party Websites</h2>
        <p>
          We have included links on this app for your use and reference. we are
          not responsible for the privacy policies on these websites. You should
          be aware that the privacy policies of these websites may differ from
          our own.
        </p>
        <h2>Security</h2>
        <p>
          The security of your personal information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage, is 100% secure. While we strive to use
          commercially acceptable means to protect your personal information, we
          cannot guarantee its absolute security.
        </p>
        <h2>Changes To This Privacy Policy</h2>
        <p>
          This Privacy Policy is effective as of November 2022 and will remain
          in effect except with respect to any changes in its provisions in the
          future, which will be in effect immediately after being posted on this
          page. we reserve the right to update or change our Privacy Policy at
          any time and you should check this Privacy Policy periodically. If we
          make any material changes to this Privacy Policy, we will notify you
          either through the email address you have provided us, or by placing a
          prominent notice on our app.
        </p>
        <h2>Contact Information</h2>
        <p>
          For any questions or concerns regarding the privacy policy, please
          send us an email to{" "}
          <a href="mailto:support@messagator.app">support@messagator.app</a>.
        </p>
        <ul>
          <li>
            Website: <a href="https://messagator.app/">messagator.app</a>
          </li>
          <li>
            Phone: <a href="tel:+201125962610">+201125962610</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
